.status {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0px 30px;
	height: -webkit-fill-available;
}

.status p {
	color: gray;
}

img {
	width: 110px;
}
